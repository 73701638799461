<template>
  <div class="result">
    <c-header></c-header>
    <div class="result__header">
      <div class="result__header-con c-width">
        <c-ServiceSelect
          class="select"
          :serviceOptions="serviceOptions"
          @selectValue="getServiceId"
          :docType="docType"
        ></c-ServiceSelect>
        <c-search :val.sync="keyword" @search="searchResult"></c-search>
      </div>
    </div>
    <div class="result__content c-width">
      <div class="empty" v-if="!searchContent.length">
        <p>搜索结果，共0条</p>
        <div class="empty__img">
          <img src="../../assets/images/empty-result-img.png" alt width="331" />
          <p>暂无符合条件的搜索结果～～</p>
        </div>
        <p class="notice">
          没找到您期待的内容，请尝试其它搜索词，或联系
          <a
            href="https://vhall.s4.udesk.cn/im_client/?web_plugin_id=15038"
            target="_blank"
          >客服</a>
        </p>
      </div>
      <div class="content" v-if="searchContent.length">
        <p class="count">已找到记录{{ totalNum }}条</p>
        <div class="content-item" v-for="(item, index) in searchContent" :key="index">
          <h3>
            <router-link :to="{ name: 'docs/show', params: { id: item.id } }">{{ item.title }}</router-link>
          </h3>
          <p class="content-item-con">
            <span v-for="(content, index) in item.contents" :key="index" v-html="content"></span>
            ...
          </p>
          <div class="content-item-resource">
            来自：
            <div class="breads" v-for="(bread, index) in item.bread" :key="index">
              <router-link
                :to="{ name: 'docs/show', params: { id: bread.doc_id } }"
              >{{ bread.doc_name }}</router-link>
              <span class="separator" v-if="index !== item.bread.length - 1">></span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="result__page c-width">
      <el-pagination
        :total="total"
        layout="prev,pager,next"
        :current-page.sync="currentPage"
        background
        @current-change="changeCurrent"
      ></el-pagination>
    </div>
    <c-footer></c-footer>
    <back-totop></back-totop>
  </div>
</template>

<script>
import cSearch from './components/search'
import cServiceSelect from './components/serviceSelect'
import cHeader from '../layout/components/header'
import cFooter from '../layout/components/footer'
import backTotop from '../layout/components/backToTop'
export default {
  data() {
    return {
      // serviceValue: '全部',
      serviceOptions: [
        {
          value: '',
          label: '全部'
        },
        {
          value: '1',
          label: '直播服务'
        },
        {
          value: '2',
          label: '点播服务'
        },
        {
          value: '5',
          label: '音视频互动服务'
        },
        {
          value: '4',
          label: '文档白板服务'
        },
        {
          value: '3',
          label: '聊天服务'
        },
        {
          value: '6',
          label: '表单服务'
        },
        {
          value: '7',
          label: '推送通知服务'
        },
        {
          value: '8',
          label: '数据服务'
        }
      ],
      keyword: '',
      totalNum: 0,
      currentPage: 1,
      // project_id: '',
      total: 0,
      searchContent: [],
      docType: ''
    }
  },
  created() {
    this.keyword = this.$route.params.keyword
    const docType = this.$route.params.doc_type
    this.serviceOptions.filter((item) => {
      if (item.label === docType) {
        this.service_id = item.value
      }
    })
    this.docType = docType
    this.getResult()
  },
  methods: {
    getResult() {
      let params = {
        search: this.keyword,
        page: this.currentPage,
        service_id: this.service_id
      }
      // if (this.project_id) {
      //   params = Object.assign({}, params, { project_id: this.project_id })
      // }
      const loading = this.$loading()
      this.$fetch('getSearchDetail', params)
        .then(({ data }) => {
          let allResult = data.result
          this.searchContent = allResult
          // this.searchContent = allResult.filter((item) => {
          //   // 目前19|20|21|22|23|24|25是saas的文档
          //   // 2|3|4|5|6|7|11|15|18是paas的文档
          //   return item.project_id <= 18
          // })
          this.$forceUpdate()
          // 总条数无法前端过滤
          this.totalNum = this.total = data.countNum
          loading.close()
        })
        .catch((e) => {
          loading.close()
          this.$message.error(e.msg)
        })
    },
    searchResult() {
      this.currentPage = 1
      this.getResult()
    },
    changeCurrent(current) {
      this.currentPage = current
      this.getResult()
    },
    getServiceId(item) {
      this.currentPage = 1
      this.service_id = item
    }
  },
  components: { cSearch, cHeader, cFooter, backTotop, cServiceSelect }
}
</script>
<style lang="less" scoped>
@import '../../assets/less/varible.less';
.result {
  &__header {
    padding: 40px 0;
    background: #f5f6f9;
    &-con {
      display: flex;
      .select {
        padding-right: 12px;
      }
    }
  }
  &__content {
    min-height: 700px;
    padding: 20px 0;
    .empty {
      p {
        font-size: 14px;
        color: #999;
        line-height: 20px;
      }
      &__img {
        margin-top: 20px;
        display: inline-block;
        text-align: center;
        p {
          margin-top: 20px;
          font-weight: 600;
        }
      }
      .notice {
        color: #333;
        font-size: 16px;
        margin-top: 70px;
        a {
          color: @mainColor;
        }
      }
    }
    .content {
      .count {
        font-size: 14px;
        color: #9aa3ac;
        line-height: 20px;
        margin-bottom: 20px;
      }
      &-item {
        h3 {
          font-size: 18px;
          margin-bottom: 12px;
          color: @hoversColor;
          font-weight: 600;
          margin-top: 24px;
        }
        &-con {
          font-size: 14px;
          color: @hoversColor;
          line-height: 20px;
        }
        &-resource {
          font-size: 14px;
          margin-top: 10px;
          color: #9aa3ac;
          padding-bottom: 16px;
          .breads {
            display: inline-block;
            a {
              &:hover {
                color: @mainColor;
              }
            }
            .separator {
              margin: 0 3px;
            }
          }
        }
      }
    }
  }
  .result__page {
    margin: 30px auto;
  }
}
</style>
